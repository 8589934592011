import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Store } from 'types/store';
import { useApi } from 'utils/api';
import { Symptom } from 'utils/screenIndicators/utils';
import tracking from 'utils/tracking';
import { mapResponseToError } from 'utils/common';
import type { ProgressiveResponse } from '../types';

export const useGetSymptoms = (): ProgressiveResponse<Symptom[]> => {
  const api = useApi();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const symptomsEndpoint = `/api/symptoms?is_follow_up=${!!search.get('is_follow_up')}`;
  const isSymptomScreen = location.pathname.includes('symptoms');
  const user = useSelector((store: Store) => store.global_reducer.current_user);

  const { data, isLoading, isSuccess, status } = useQuery(
    ['symptomsData', user.attributes.patient?.id],
    () => api.get<Symptom[]>(symptomsEndpoint),
    {
      enabled: !!api && isSymptomScreen,
      onError: err => {
        const errorMessage = mapResponseToError(err);
        tracking.errorV2('Error when call API /api/symptoms', err, { errorMessage });
      }
    }
  );

  return useMemo(() => ({ isLoading, isSuccess, data: data?.data || [], status }), [data, isLoading]);
};
