import React, { useContext, createContext, useState, ReactNode } from 'react';
import { noop } from './default-values';
import tracking from './tracking';

interface AcceptScriptProviderProps {
  children: ReactNode;
}

export interface ContextProps {
  loaded: boolean;
  error: boolean;
  injectScript(): void;
}

const AcceptScriptContext = createContext<ContextProps>({
  loaded: false,
  error: false,
  injectScript: noop
});

const AcceptScriptProvider = ({ children }: AcceptScriptProviderProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const injectScript = () => {
    const checkForScript = setInterval(() => {
      if (!window || !document || !process.env.REACT_APP_ACCEPTJS_URL) {
        setError(true);
        const errorMessage =
          'Error loading Accept.js: REACT_APP_ACCEPTJS_URL undefined or problem identifying the window or document.';
        tracking.errorV2(errorMessage, undefined, { tags: ['error', 'acceptjs'] });
        clearInterval(checkForScript);
        return;
      }

      const loadedScripts = Array.from(document.scripts).map(script => script.src);

      if (!loadedScripts.includes(process.env.REACT_APP_ACCEPTJS_URL)) {
        const tag = document.createElement('script');
        tag.src = process.env.REACT_APP_ACCEPTJS_URL;
        tag.type = 'text/javascript';
        document.body.appendChild(tag);
      }

      if (window.Accept) {
        setLoaded(true);
        clearInterval(checkForScript);
      }
    }, 500);

    setTimeout(() => {
      if (loaded || error) return;

      clearInterval(checkForScript);
      setError(true);
      tracking.errorV2('Error loading Accept.js', undefined, { tags: ['error', 'acceptjs'] });
    }, 10000);
  };

  const value = {
    loaded,
    error,
    injectScript
  };
  return <AcceptScriptContext.Provider value={value}>{children}</AcceptScriptContext.Provider>;
};

export interface WithAcceptScriptProps {
  acceptScript?: ContextProps;
}

const withAcceptScript = <P extends WithAcceptScriptProps>(Component: React.ComponentType<P>) => {
  return (props: React.PropsWithChildren<P>) => {
    const acceptScript = useContext(AcceptScriptContext);
    const { children } = props;

    return (
      <Component {...props} acceptScript={acceptScript}>
        {children}
      </Component>
    );
  };
};

const useAcceptScript = (): ContextProps => useContext(AcceptScriptContext);

export { AcceptScriptProvider, useAcceptScript, withAcceptScript, AcceptScriptContext };
