import { useEffect } from 'react';
import axios from 'axios';
import useDocumentVisibility from '../../utils/useDocumentVisibility';
import tracking from '../../utils/tracking';

const currentVersion = process.env.REACT_APP_GIT_SHA;

export default function VersionChecker({ children }) {
  const visibility = useDocumentVisibility();

  useEffect(() => {
    if (visibility === 'visible') {
      (async () => {
        try {
          const {
            data: { version }
          } = await axios({
            method: 'get',
            url: `${process.env.PUBLIC_URL}/version.json?buster=${Date.now()}`,
            baseURL: `https://${window.location.hostname}`
          });

          if (currentVersion !== version) {
            tracking.errorV2(`Versions mismatch: local(${currentVersion}) - remote(${version})`, undefined, {
              tags: ['VersionChecker', 'error']
            });
          }
        } catch (e) {
          tracking.errorV2(`Error when fetching app version`, e, {
            tags: ['VersionChecker', 'error']
          });
        }
      })();
    }
  }, [visibility]);

  return children;
}
