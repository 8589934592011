import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryPropsWithFallback } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { Store } from 'types/store';
import { useLocation } from 'react-router';
import tracking from './utils/tracking';
import { Container, Text } from './components/dashboard/NotCompletedFlow/styled';
import { Wrapper } from './containers/patient/Wrapper';

const ErrorFallback = () => (
  <Wrapper>
    <Container>
      <Text>
        An unforeseen error has occurred. Our technical staff has been notified and are working on a solution to the
        problem. You can try navigating back to the <a href="/">login page</a> to continue.
      </Text>
    </Container>
  </Wrapper>
);

/**
 * need to send the referrer
 */
export const ErrorPage = () => {
  const { search } = useLocation();
  useEffect(() => {
    const { error, ...info } = Object.fromEntries(new URLSearchParams(search));
    tracking.errorV2(`On Error Page with params: ${search}`, new Error(error), {
      tags: ['ErrorBoundary', 'error'],
      info
    });
  }, [search]);

  return <ErrorFallback />;
};

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const { id, email, first_name, last_name, patient } = useSelector(
    (state: Store) => state.global_reducer.current_user.attributes
  );
  const { pathname } = useLocation();

  useEffect(() => {
    if (!id) return;
    datadogRum.setUser({
      id: String(id),
      patientId: String(patient?.id)
    });
    datadogLogs.setUser({
      id: String(id),
      patientId: String(patient?.id)
    });
  }, [id, email, first_name, last_name, patient]);

  const onError: ErrorBoundaryPropsWithFallback['onError'] = (error, info) => {
    datadogRum.setGlobalContextProperty('error_boundary', true);
    tracking.errorV2(`UNFORSEEN ERROR: ${pathname}`, error, {
      tags: ['ErrorBoundary', 'error'],
      info
    });
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
