import { datadogRum } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import { StatusType, datadogLogs } from '@datadog/browser-logs';
import { RNWindow } from './react-native-webview-window';

interface Data {
  message?: string;
  e?: unknown;
  info?: unknown;
  callback?: () => never;
}

export const isAxiosError = (error: unknown): error is AxiosError => {
  return typeof error === 'object' && error !== null && 'config' in error;
};

const transformReasonToObject = (reason: unknown) => {
  if (reason instanceof Error) {
    try {
      JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));
    } catch (err) {
      return reason;
    }
  }

  return reason;
};

const addError = (data: Data): unknown => {
  if (isAxiosError(data.e) && data.e.config) {
    data.e.config.data = '';
  }

  datadogRum.addError(data.e, data);

  if (!(data.e instanceof Error)) {
    datadogRum.addError(new Error('Calling datadogRum.addError without an instance of Error!'), {
      ...data,
      errorType: 'DATADOG_MISUSED'
    });
  }

  return data.e;
};

const critical = addError;
const error = addError;
const warning = addError;
const info = addError;
const debug = addError;

const errorV2 = (message: string, reason?: Error | unknown, context?: Record<string, unknown>) => {
  if (isAxiosError(reason) && reason.config) {
    reason.config.data = '';
  }

  const err = new Error(message);

  if (reason instanceof Error) {
    err.stack = reason.stack;
  }

  datadogRum.addError(err, {
    reason: transformReasonToObject(reason),
    isWebView: !!RNWindow.ReactNativeWebView,
    ...context
  });
};

const log = (level: StatusType) => (message: string, context?: Record<string, unknown>) => {
  const enhancedContext = {
    isWebView: !!RNWindow.ReactNativeWebView,
    ...context
  };
  datadogLogs.logger.log(message, enhancedContext, level);
};

export default {
  error,
  info,
  critical,
  warning,
  debug,
  addError,
  errorV2,
  warningV2: log('warn'),
  infoV2: log('info'),
  debugV2: log('debug')
};
