import React, { useEffect, ReactNode } from 'react';
import { useConfig } from 'contexts/ConfigContext';
import tracking from 'utils/tracking';
import { MaintenancePage } from '../MaintenancePage';

export const MaintenanceBoundary = ({ children }: { children: ReactNode }): JSX.Element => {
  const config = useConfig();

  useEffect(() => {
    if (config.maintenanceMode) {
      tracking.infoV2('[Maintenance] mode viewed');
    }
  }, [config.maintenanceMode]);

  return config.maintenanceMode ? <MaintenancePage /> : <>{children}</>;
};
